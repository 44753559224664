import Web3 from "web3";

import erc20Abi from "./abis/ERC20.json";
import { logError } from "../utils/logs";
import { CONTRACT_ADDRESSES, CHAIN_ID } from "../config/Constants";
import { ERC20 } from "./types/ERC20";

let web3: any;
let web3Infura: any;

const infuraUrl =
  CHAIN_ID === 1
    ? `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
    : `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`;

try {
  web3 = new Web3(window?.web3?.currentProvider);
  web3Infura = new Web3(infuraUrl);
} catch (e) {
  logError("Connect Web3", e);
}

export const setWeb3Provider = (provider: any) => {
  web3 = new Web3(provider);
};

export const erc20TokenContract = (tokenAddress: string) => {
  let contract: ERC20;
  try {
    if (window?.web3?.currentProvider || web3) {
      contract = new web3.eth.Contract(erc20Abi, tokenAddress);
    } else {
      contract = new web3Infura.eth.Contract(erc20Abi, tokenAddress);
    }
    return contract;
  } catch (e) {
    logError("erc20TokenContract", e);
  }
};

export const getWeb3Provider = () => {
  if (window?.web3?.currentProvider || web3) return web3;
  else return web3Infura;
};
