let params = {
  userEs: {
    id: true,
    txCount: true,
    rebaseCount: true,
    vaultCreatedCount: true,
    totalDepositedAmount: true,
    totalWithdrawnAmount: true,
    activeRebaseCount: true,
    passiveRebaseCount: true,
    userVaultInfos: {
      id: true,
      txCount: true,
      totalDepositedLiquidity: true,
      totalDepositedAmount: true,
      totalCollectedAmount: true,
      totalIndexAmount: true,
      totalWithdrawnAmount: true,
    },
  },
};

let paramsp2 = {
  userEs: {
    id: true,
    txCount: true,
    rebaseCount: true,
    vaultCreatedCount: true,
    totalDepositedAmount: true,
    totalWithdrawnAmount: true,
    activeRebaseCount: true,
    passiveRebaseCount: true,
    stakeCounts: true,
    stakeAmounts: true,
    unstakeCounts: true,
    unstakeAmounts: true,
    rewardClaiminAlt: true,
    rewardClaiminPilot: true,

    userVaultInfos: {
      id: true,
      txCount: true,
      totalDepositedLiquidity: true,
      totalDepositedAmount: true,
      totalCollectedAmount: true,
      totalIndexAmount: true,
      totalWithdrawnAmount: true,
      stakeCounts: true,
      stakeAmounts: true,
      unstakeCounts: true,
      unstakeAmounts: true,
      rewardClaiminAlt: true,
      rewardClaiminPilot: true,
    },
  },
};

let poolParams = {
  userVaultInfos: {
    id: true,
    txCount: true,
    totalRebaseCount: true,
    totalDepositedLiquidity: true,
    totalDepositedAmount: true,
    totalCollectedAmount: true,
    totalWithdrawnAmount: true,
    stakeCounts: true,
    stakeAmounts: true,
    unstakeCounts: true,
    unstakeAmounts: true,
    rewardClaiminAlt: true,
    rewardClaiminPilot: true,
    user: {
      id: true,
      rebaseCount: true,
      vaultCreatedCount: true,
    },
  },
};

export { params, paramsp2, poolParams };
