import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
