import { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Tab,
  ListItem,
  ListItemText,
  List,
  Drawer,
  IconButton,
  Link as LinkMaterial,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Skeleton } from "@material-ui/lab";

import {
  Logo,
  LogoTick,
  WDialog,
  CloseButton,
  TokenLogo,
  RowAlignCenter,
} from "../";
import useStyles from "./styles";
import { PILOT_LOGO, PILOT_ADDRESS } from "../../constants/main";
import { updatePilotPrice } from "../pilotLogo";

interface IMainHeader {
  bottom?: boolean;
}

const MainHeader: React.FC<IMainHeader> = ({ bottom = false }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));
  const xs_ = useMediaQuery(theme.breakpoints.down("xs"));

  const [price, setPrice] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);

  const navs = [
    {
      label: "Round1",
      to: "/",
      hrefs: ["/"],
    },
    {
      label: "Round2",
      to: "/phase2",
      hrefs: ["/phase2"],
    },
  ];

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getPrice = async () => {
    const price = await updatePilotPrice();
    setPrice(price);
  };

  useEffect(() => {
    getPrice();
    setInterval(() => {
      getPrice();
    }, 30000);
  }, []);

  const tabs = (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        classes={{ indicator: classes.indicator }}
      >
        {navs.map((nav, idx) => (
          <Tab
            component={Link}
            key={nav.label}
            disableRipple
            classes={{ root: classes.tabRoot }}
            label={nav.label}
            {...a11yProps(idx)}
            to={nav.to}
          />
        ))}
      </Tabs>
    </>
  );

  const drawer = (
    <Drawer
      variant="temporary"
      anchor="bottom"
      open={openDrawer}
      classes={{ root: classes.drawerRoot, paper: classes.drawerPaper }}
      onClose={handleDrawerClose}
      BackdropProps={{ classes: { root: classes.backdropRoot } }}
    >
      <List>
        <ListItem
          button
          className={classes.drawerListItemHoverNone}
          disableRipple
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Logo className={classes.logo} />
            </Grid>
            <Grid item>
              <CloseButton onClick={handleDrawerClose} />
            </Grid>
          </Grid>
        </ListItem>

        {navs.map((nav, idx) => (
          <ListItem
            onClick={handleDrawerClose}
            key={nav.label}
            button
            className={classes.drawerListItem}
            component={Link}
            to={nav.to}
          >
            <ListItemText>{nav.label}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  useEffect(() => {
    let idx = navs.findIndex((nav) => {
      return nav.hrefs.includes(pathname);
    });

    if (idx !== -1) setTabValue(idx);
  }, [pathname]);

  return (
    <>
      <WDialog open={open} close={() => setOpen(false)} />
      <Box className={classes.parentApp}>
        <AppBar
          className={`${bottom ? classes.goToBottom : classes.appBar} ${
            classes.bottomBorder
          }`}
          elevation={0}
          color={"transparent"}
          position={bottom ? "fixed" : "static"}
        >
          <Container className={classes.container} maxWidth="xl">
            <Toolbar
              className={bottom ? classes.toolBarAfter : classes.toolBarBefore}
            >
              {sm_ ? (
                <Link to="/">
                  <LogoTick
                    className={xs_ ? classes.logoTick_xs : classes.logoTick}
                  />
                </Link>
              ) : (
                <Link
                  to="/"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Logo className={classes.logo} />
                </Link>
              )}

              {sm_ ? drawer : tabs}

              <Box marginLeft="auto" display="flex" alignItems="center">
                {!xs_ && (
                  <LinkMaterial
                    href={`https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${PILOT_ADDRESS}`}
                    target="_blank"
                    className={classes.pilotPrice}
                  >
                    <RowAlignCenter
                      element01ClassName={classes.item01Center}
                      justify="flex-start"
                      elements01={
                        <TokenLogo
                          className={classes.pilotLogo}
                          uri={PILOT_LOGO}
                        />
                      }
                      elements02={
                        <Typography
                          className={classes.pilotText}
                          variant="body2"
                        >
                          Pilot
                        </Typography>
                      }
                    />
                    {price !== 0 ? (
                      <Typography
                        variant="body2"
                        style={{ minWidth: 60, textAlign: "right" }}
                      >{`$ ${price}`}</Typography>
                    ) : (
                      <Skeleton width={80} height={30} />
                    )}
                  </LinkMaterial>
                )}

                {sm_ && (
                  <Box marginLeft="20px">
                    <IconButton
                      color="primary"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      // className={clsx(classes.menuButton, open && classes.hide)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </>
  );
};

export default MainHeader;
