import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  conatiner: {
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("sm")]: {
      padding: "0 2rem 2rem 2rem",
    },
  },
  marginTop: {
    marginTop: 15,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop25: {
    marginTop: 25,
  },
  marginTop25Up: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 25,
    },
  },
  marginTop35Up: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 42,
    },
  },
  heading: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    marginBottom: "20px",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  bold: {
    fontWeight: 500,
  },
  link: {
    color: theme.palette.primary.main,
  },
  button: {
    color: "white",
    borderRadius: theme.shape.borderRadius / 2,
  },
  boxContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    border: "1px solid rgba(255,255,255,0.1)",
    borderRadius: theme.shape.borderRadius * 2 + 4,
    padding: "25px 0px",
  },
  boxText: {
    color: theme.palette.primary.main,
    fontSize: "2rem",
    fontWeight: 500,
  },
  paddingLeftright: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 110px",
    },
  },
}));

export default useStyles;
