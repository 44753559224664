import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { subgraphRequest } from "../../utils/helpers";
import { CTooltip } from "..";
import { conciseAddress, ogTrunc, trunc } from "../../utils/formating";
import { params, paramsp2, poolParams } from "./queries";
import ErrorIcon from "@material-ui/icons/Error";
import { checkBlackList } from "../../utils/helpers";

import { Dialog, Copy } from "..";

import SearchBar from "material-ui-search-bar";
import {
  Button,
  Grid,
  CircularProgress,
  Container,
  Typography,
  useTheme,
  Tooltip,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import Empty from "../emptyState/empty";

export interface DataObj {
  address: string;
  name: string;
}

export interface Data {
  id: string;
  rebaseCount: string;
  activeRebaseCount: string;
  passiveRebaseCount: string;
  txCount: string;
  totalCollectedAmount: string;
  totalDepositedAmount: string;
  totalWithdrawnAmount: string;
  vaultCreatedCount: string;
  stakeCount: string;
  unstakeCount: string;
  rewardsPilot: string;
  rewardsAlt: string;
}

interface IData {
  id: string;
  totalRebaseCount: string;
  totalDepositedAmount: string;
  totalCollectedAmount: string;
  totalIndexAmount: string;
  totalWithdrawnAmount: string;
  txCount: string;
  stakeCounts: string;
  unstakeCounts: string;
  rewardClaiminPilot: string;
  rewardClaiminAlt: string;
  user: IUser;
}

interface IUser {
  id: string;
  rebaseCount: string;
  vaultCreatedCount: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  title: string;
}

const headCells: HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Address",
    title: "Hello world",
  },
  {
    id: "rebaseCount",
    numeric: false,
    disablePadding: false,
    label: "Rebase Count", // Rebase here
    title: "Number of rebases",
  },
  {
    id: "activeRebaseCount",
    numeric: false,
    disablePadding: false,
    label: "Active Rebases",
    title: "Number of rebases in active vaults",
  },
  {
    id: "passiveRebaseCount",
    numeric: false,
    disablePadding: false,
    label: "Passive Rebases",
    title: "Number of rebases in passive vaults",
  },
  // {
  //   id: "totalCollectedAmount",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Native Fees",
  //   title: "Fees collected in native tokens",
  // },
  {
    id: "totalDepositedAmount",
    numeric: false,
    disablePadding: false,
    label: "Liquidity",
    title: "Liquidity added by user",
  },
  {
    id: "totalWithdrawnAmount",
    numeric: false,
    disablePadding: false,
    label: "Total Withdraws", //Withdraw
    title: "Total amount withdrawn",
  },
  {
    id: "vaultCreatedCount",
    numeric: false,
    disablePadding: false,
    label: "Vaults Created",
    title: "Vaults created by user",
  },
  // new one
  {
    id: "stakeCount",
    numeric: false,
    disablePadding: false,
    label: "Stake Counts",
    title: "No. of times lp staked by user",
  },
  {
    id: "unstakeCount",
    numeric: false,
    disablePadding: false,
    label: "Unstake Counts",
    title: "No. of times lp unstaked by user",
  },
  {
    id: "rewardsPilot",
    numeric: false,
    disablePadding: false,
    label: "Rewards Pilot",
    title: "Rewards claimed by user in pilot tokens",
  },
  {
    id: "rewardsAlt",
    numeric: false,
    disablePadding: false,
    label: "Rewards Alt",
    title: "Rewards claimed by user in alt tokens",
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  addressObj: DataObj;
  pathname: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center">S.No.</TableCell>
        {headCells.map(
          (headCell) =>
            ((props.addressObj.address === "" &&
              headCell.id !== "rebaseCount") ||
              (headCell.id !== "activeRebaseCount" &&
                headCell.id !== "passiveRebaseCount" &&
                headCell.id !== "vaultCreatedCount" &&
                (props.addressObj.address !== "" ||
                  headCell.id !== "rebaseCount"))) && (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  props.pathname === "/" &&
                  (headCell.id === "stakeCount" ||
                    headCell.id === "unstakeCount" ||
                    headCell.id === "rewardsPilot" ||
                    headCell.id === "rewardsAlt")
                    ? [classes.tooltip, classes.none].join(" ")
                    : classes.tooltip
                }
              >
                {headCell.id !== "id" ? (
                  <Box className={classes.box}>
                    <Typography>
                      <CTooltip title={headCell.title}>
                        <ErrorIcon style={{ fontSize: "0.8rem" }} />
                      </CTooltip>
                    </Typography>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label === "Vaults Created" && sm_
                        ? "Vaults"
                        : headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </Box>
                ) : (
                  <Box>{headCell.label}</Box>
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

interface IPathname {
  pathname: string;
}

const EnhancedTable: React.FC<IPathname> = ({ pathname = "" }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("desc");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState<boolean>(false);
  const [addressObj, setAddress] = useState<DataObj>({ address: "", name: "" });
  const [rows, setRows] = useState<Data[]>([]);
  const [tableRows, setTableRows] = useState<Data[]>(rows);
  const [orderBy, setOrderBy] = React.useState<keyof Data>(
    addressObj.address ? "totalCollectedAmount" : "rebaseCount"
  );
  const [searched, setSearched] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down("xs"));
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));

  const getPoolData = async (address: string) => {
    setLoading(true);
    if (address !== "") {
      //@ts-ignore
      poolParams.userVaultInfos.__args = {
        where: {
          vault: address,
        },
        first: 1000,
      };
      const data = await subgraphRequest(
        "https://api.thegraph.com/subgraphs/name/hamzabhatti125/leaderboardv2round2",
        poolParams
      );
      if (data && data.userVaultInfos.length > 0) {
        const test: Data[] = data.userVaultInfos
          .filter((row: IData) => checkBlackList(row.user.id))
          .map((row: IData) => {
            const test2: any = {
              id: row.user.id,
              rebaseCount: row.totalRebaseCount,
              totalCollectedAmount: row.totalCollectedAmount,
              totalDepositedAmount: row.totalDepositedAmount,
              totalWithdrawnAmount: row.totalWithdrawnAmount,
              poolCreatedCount: row.user.vaultCreatedCount,
              txCount: row.txCount,
              stakeCount: row.stakeCounts,
              unstakeCount: row.unstakeCounts,
              rewardsPilot: row.rewardClaiminPilot,
              rewardsAlt: row.rewardClaiminAlt,
            };
            return test2;
          });
        setRows(test);
        setTableRows(test);
        setPage(0);
        setLoading(false);
      } else {
        setRows([]);
        setTableRows([]);
        setLoading(false);
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    //@ts-ignore
    params.userEs.__args = {
      first: 1000,
    };
    let dataRow;
    if (pathname === "/phase2") {
      dataRow = await subgraphRequest(
        "https://api.thegraph.com/subgraphs/name/hamzabhatti125/leaderboardv2round2",
        paramsp2
      );
    } else {
      dataRow = await subgraphRequest(
        "https://api.thegraph.com/subgraphs/name/hamzabhatti125/leaderboardv2",
        params
      );
    }
    console.log("data here: ", dataRow.userEs);
    const checkData = dataRow.userEs
      .filter((row: any) => checkBlackList(row.id))
      .map((row: any) => {
        const value: Data = {
          id: row.id,
          rebaseCount: row.rebaseCount,
          activeRebaseCount: row.activeRebaseCount,
          passiveRebaseCount: row.passiveRebaseCount,
          totalCollectedAmount: row.totalCollectedAmount,
          totalDepositedAmount: row.totalDepositedAmount,
          totalWithdrawnAmount: row.totalWithdrawnAmount,
          vaultCreatedCount: row.vaultCreatedCount,
          txCount: row.txCount,
          stakeCount: row.stakeCounts ?? "123",
          unstakeCount: row.unstakeCounts ?? "789",
          rewardsPilot: row.rewardClaiminPilot ?? "456",
          rewardsAlt: row.rewardClaiminAlt ?? "789",
        };
        return value;
      });
    setRows(checkData);
    setTableRows(checkData);
    setPage(0);
    setLoading(false);
  };

  useEffect(() => {
    if (addressObj.address !== "") {
      getPoolData(addressObj.address);
    } else {
      getData();
    }
  }, [addressObj.address, pathname]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    console.log(newPage);
  };

  const setItem = (value: DataObj) => {
    setAddress(value);
    setOpen(false);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const requestSearch = (searchedVal: string) => {
    const filteredRows = rows.filter((row) => {
      return row.id.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setTableRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const CustomizedSelects = () => {
    return (
      <div className={classes.selectItem}>
        <Button
          style={{ minWidth: xs_ ? 0 : 200 }}
          onClick={handleOpen}
          endIcon={<ArrowDropDownIcon style={{ justifyContent: "flex-end" }} />}
        >
          {addressObj.name === "" || addressObj.name === "Show All Pools" ? (
            <Typography style={{ textTransform: "capitalize" }}>
              Select Pair
            </Typography>
          ) : (
            addressObj.name
          )}
        </Button>
      </div>
    );
  };

  const getConvertedTable = (tableRows: Data[]) => {
    const updatedData = tableRows.map((row: Data) => {
      return {
        ...row,
        rebaseCount: parseFloat(row.rebaseCount),
        activeRebaseCount: parseFloat(row.activeRebaseCount),
        passiveRebaseCount: parseFloat(row.passiveRebaseCount),
        txCount: parseFloat(row.txCount),
        totalCollectedAmount: parseFloat(row.totalCollectedAmount),
        totalDepositedAmount: parseFloat(row.totalDepositedAmount),
        totalWithdrawnAmount: parseFloat(row.totalWithdrawnAmount),
        poolCreatedCount: parseFloat(row.vaultCreatedCount),
        stakeCount: parseFloat(row.stakeCount),
        unstakeCount: parseFloat(row.unstakeCount),
        rewardsPilot: parseFloat(row.rewardsPilot),
        rewardsAlt: parseFloat(row.rewardsAlt),
      };
    });
    return updatedData;
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} close={() => setOpen(!open)} setItem={setItem} />
      <Paper className={classes.paper}>
        <Grid container className={classes.wrap}>
          <Grid item md={8} sm={12} xs={12}>
            <div className={classes.bar}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                style={{
                  marginLeft: "10px",
                  background: "transparent",
                  border: "1px solid rgba(255,255,255,0.1)",
                  color: "#EFA618",
                }}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Grid
              container
              alignItems="stretch"
              direction="row"
              justifyContent="flex-end"
            >
              <Grid item style={{ marginRight: "10px" }}>
                {CustomizedSelects()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              addressObj={addressObj}
              pathname={pathname}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    rowSpan={3}
                    colSpan={!addressObj.address ? 10 : sm_ ? 4 : 10}
                  >
                    <Container className={classes.boxContainer}>
                      <CircularProgress size={50} />
                    </Container>
                  </TableCell>
                </TableRow>
              ) : tableRows.length === 0 && !loading ? (
                <TableRow>
                  <TableCell
                    rowSpan={3}
                    colSpan={!addressObj.address ? 10 : sm_ ? 4 : 10}
                  >
                    <Box style={{ minHeight: !sm_ ? "470px" : "0" }}>
                      <Empty />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(
                  getConvertedTable(tableRows),
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align="center">
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {conciseAddress(row.id)}
                          <Copy value={row.id} />
                        </TableCell>

                        {addressObj.address && (
                          <TableCell align="left">
                            {ogTrunc(row.rebaseCount) > 0 ? (
                              <Tooltip
                                arrow
                                placement="left"
                                title={row.rebaseCount}
                              >
                                <Typography>
                                  {trunc(row.rebaseCount)}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography>{trunc(row.rebaseCount)}</Typography>
                            )}
                          </TableCell>
                        )}
                        {!addressObj.address && (
                          <TableCell align="left">
                            {ogTrunc(row.activeRebaseCount) > 0 ? (
                              <Tooltip
                                arrow
                                placement="left"
                                title={row.activeRebaseCount}
                              >
                                <Typography>
                                  {trunc(row.activeRebaseCount)}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography>
                                {trunc(row.activeRebaseCount)}
                              </Typography>
                            )}
                          </TableCell>
                        )}
                        {!addressObj.address && (
                          <TableCell align="left">
                            {ogTrunc(row.passiveRebaseCount) > 0 ? (
                              <Tooltip
                                arrow
                                placement="left"
                                title={row.passiveRebaseCount}
                              >
                                <Typography>
                                  {trunc(row.passiveRebaseCount)}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography>
                                {trunc(row.passiveRebaseCount)}
                              </Typography>
                            )}
                          </TableCell>
                        )}
                        <TableCell align="left">
                          {ogTrunc(row.totalDepositedAmount) > 0 ? (
                            <Tooltip
                              arrow
                              placement="left"
                              title={row.totalDepositedAmount}
                            >
                              <Typography>
                                {trunc(row.totalDepositedAmount)}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography>
                              {trunc(row.totalDepositedAmount)}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {ogTrunc(row.totalWithdrawnAmount) > 0 ? (
                            <Tooltip
                              arrow
                              placement="left"
                              title={row.totalWithdrawnAmount}
                            >
                              <Typography>
                                {trunc(row.totalWithdrawnAmount)}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography>
                              {trunc(row.totalWithdrawnAmount)}
                            </Typography>
                          )}
                        </TableCell>
                        {!addressObj.address && (
                          <TableCell align="left">
                            {row.poolCreatedCount}
                          </TableCell>
                        )}
                        {pathname === "/phase2" && (
                          <>
                            <TableCell align="left">{row.stakeCount}</TableCell>

                            <TableCell align="left">
                              {row.unstakeCount}
                            </TableCell>
                            <TableCell align="left">
                              {ogTrunc(row.rewardsPilot) > 0 ? (
                                <Tooltip
                                  arrow
                                  placement="left"
                                  title={row.rewardsPilot}
                                >
                                  <Typography>
                                    {trunc(row.rewardsPilot)}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography>
                                  {trunc(row.rewardsPilot)}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {ogTrunc(row.rewardsAlt) > 0 ? (
                                <Tooltip
                                  arrow
                                  placement="left"
                                  title={row.rewardsAlt}
                                >
                                  <Typography>
                                    {trunc(row.rewardsAlt)}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography>{trunc(row.rewardsAlt)}</Typography>
                              )}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })
              )}
              {emptyRows > 0 && tableRows.length > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} style={{ borderBottom: "none" }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {tableRows.length > rowsPerPage ? (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        ) : null}
      </Paper>
    </div>
  );
};

export default EnhancedTable;
