export const CONTRACT_ADDRESSES = {
  flashLiquidity: "0x2a10D07714f94643c6627a5b9CD56655D7F0d01b",
  //balance: '0x6B9aE204B9779fbd15Fdbd027c9F0d266A32f0b7',
  balance: "0x84Dc72D9Ce4Bf5cA2b1E6e7a3d57C16c723cf0f3",
  nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
  multicall: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
};
export const CHAIN_ID =
  process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? 1 : 4;

export const UN_KNOWN_LOGO =
  "https://gateway.pinata.cloud/ipfs/QmNX2QerTxTm1RThD7Dc9X5uS9VFnQxmMotaMFhK5GYbBk";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const ETHERSCAN_TX_BASE_URL =
  CHAIN_ID === 1
    ? "https://etherscan.io/tx/"
    : "https://rinkeby.etherscan.io/tx/";

export const DEFAULT_SLIPPAGE = "0.5";
export const DEFAULT_DEADLINE = "30";

export const GRAPH_END_POINT =
  CHAIN_ID === 1
    ? "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3"
    : "https://api.thegraph.com/subgraphs/name/mubashir-ali-baig/uniswap-v3-subgraph";

export const DEFAULT_TOKEN_LIST = CHAIN_ID === 1 ? 11 : 12;

export const PILOT_ADDRESS = "0x37c997b35c619c21323f3518b9357914e8b99525";
export const PILOT_LOGO =
  "https://gateway.pinata.cloud/ipfs/QmZkwHmYpVL3Bi17SUsmHU4pypkTXan46JH76ToRgoZJQz";

export const BLACKLIST = [
  "0x6b8B11756fd8579430735E15545698c3bb11CeF2",
  "0xff8A62692207511c0335983bf49931ede3A9b01C",
  "0x91097AcB2E86245d63a602110ACCE4A289b58324",
  "0x74b6D06b8B16ddDD0513774cf6d9E57eD1Da9d59",
  "0x1d5cf5698f8EA20CB7A84D50765634Cb84F2F0c3",
  "0x6A337d4D54e181E7704C3D2171652a1E846EAb73",
  "0xe88F87120249d5FeD990f0Ef09695F02d0561fE5",
  "0xe130087dEF658144903D38Ed0FC304B0031c2906",
  "0x405A3E7DFd8DC9188A65EC610038Eb2afE8BAa25",
  "0x91097AcB2E86245d63a602110ACCE4A289b58324",
  "0x8d53861b3FEAb2d6CEb3b11A97801BeC9fEF4bC2",
  "0xaD41CBcF564f5E2b3DEc719CE69760000Cb6da87",
  "0x1e13e5B5ACBB0C3F0fDe50fE7661fdF75df8F932",
  "0x8eeFB7A090D1D8Caea8860eb8A6B8d1A2e28Da85",
  "0xe7Ef8E1402055EB4E89a57d1109EfF3bAA334F5F",
  "0xa0e9E6B79a3e1AB87FeB209567eF3E0373210a89",
  "0xc817178813446B229d7AD144E2525fD8Ed33f284",
  "0xaac5e56A380aB10e816aD3bf226171b41a7c564f",
  "0x52be4309BA5647905Dd1AB7845BC2452D9777b9A",
  "0x40202097488E8245c2645eb736C2C94030C62532",
  //round-1 from here
  // "0x0ed00a34bae6c22d2193b0ca4ac7b2ce89678f2c",
  // "0xcc9737afeb678ee098fa6085bad00f6a393eeaae",
  // "0xc4b52cc72d5785e418b6f24a56df1b3f67674474",
  // "0xbd6239c56a7127cd583b10ed460c4e73bc91b866",
  // "0x92d630b2c59b25256160ac15d01dfd837b3ab8f7",
  // "0xb38195d16da41a69162fccf8ac8bc63b79f88ee2",
  // "0x95c99c832932a77316daa1842f514d9b8dfad3d1",
  // "0x3e248b9ea35a0628f640a3340cf7bf734c9ccd40",
  // "0x5826cb841ebb21e0a78fc353e5c75bffc7578cd7",
  // "0x00000bb5f6bd9a815511113ed18e4131ce6ff79b",
  // "0x10320ba6987230f72095826c4cf9497c093580b9",
  // "0x94a6a15e6693f5bab746f5700c64014ad06ded43",
  // "0xbe236b156768801ccaf59d3aee864f51133aeeee",
  // "0xbebf520062dc81b397036c8798ea5b03280d8c0f",
  // "0x21f179c79a159359d20a94f68b99ad88db44afca",
  // "0x66660830ce21683c7f34bef9baa9e2a0b413a32a",
  // "0x98eb100b7a6ce8cfaa6e79b0653f2ee5845a7ffd",
  // //round-2 here
  // "0x313ff40b3b90ed90d0dac823033d3bac67151b02",
  // "0x4c8c5884fa6f30c61c045020a834f2e039b0c388",
  // "0xcb304733cb0b9a82d426b13afd0f641ef1deb0e9",
  // "0x20799b34e4382ad646bc873c7d96d02ac2f9c202",
  // "0x37f6489e1e8b1bd0d07bd1855035e59aaa2bf911",
  // "0xa23e4caf4562856038b2dafde88f1d768fb123c7",
  // "0xd7fb2f16857ba09e44efbfd14c3f7651139ac0d6",
  // "0xb86cb1e14842f923e1efa6ea27de065819e24438",
  // "0xc0df3816896e6ffe55485762d45bc9e9d9897895",
  // "0xb50972be99d3310bf0bf7fbbb086dc2dde7bdac3",
  // "0x6632dc491682fc600bc3ed4162210b160c375d21",
  // "0xcc645a67f5b7e5bd9b9db9215850e392e5d02597",
  // "0xc1c515f7a74b5667bc1b65b2f74f46b3d49bc641",
  // "0x7192d259bfc2ee64d549c034ef74ab48245ff2ec",
  // "0x786235e9df905473d00b04db60d1d60b69369375",
  // "0x2b1868fa2d8ffe316531fc67efb0166dd7cfd0b3",
  // "0x6fdb4d98489bf9dfc43c2d88527a4c8f56b12422",
  // "0xbc4d08d2e7feb11ed160bdde6c3ac779d0a61827",
  // "0xbc069357d8f494bfaed9a5d606787d3ff28247fd",
  // "0x57a3e7088651b237cd363d7025554a63d47fc21f",
  // "0x7e40f1b4d933f24f6c0f59d7c176be43535ed360",
  // "0x6ac727ecc76545f0c7c6656749e5088c546335b6",
  // "0x891da97dd64a84be54b33d982b3508d6ecd25baa",
  // "0xa01cb77cfbb079ab59d0a2d936188ad19da833d3",
  // "0x72760a0a42db20fdec00b13c2b602210cad872d1",
];
