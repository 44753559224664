import React, { useState, useEffect } from "react";
import {
  Container,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  Typography,
  makeStyles,
  Link,
} from "@material-ui/core";

import graph from "../../assets/others/emptyGraph.svg";

const useStyles = makeStyles((theme) => ({
  conatiner: {
    minHeight: 375,
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
  },
}));

const Empty = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.conatiner}
    >
      <Grid item md={12} sm={12} xs={12} style={{ opacity: "0.3" }}>
        <img src={graph} width="60px" alt="Image" />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          style={{
            fontWeight: 700,
            textAlign: "center",
            opacity: "0.3",
            textTransform: "lowercase",
          }}
        >
          <span style={{ textTransform: "capitalize", marginRight: "3px" }}>
            No
          </span>
          Data Found
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Empty;
