import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& .MuiTableCell-root": {
        borderBottom: "1px solid rgba(255, 255, 255, 0.08)",
      },
      "& .MuiSvgIcon-root.MuiTableSortLabel-icon": {
        color: `${theme.palette.primary.main} !important`,
        fontSize: 24,
      },
    },
    boxContainer: {
      // border: "1px solid red",
      textAlign: "center",
      minHeight: "470px",
      paddingTop: "200px",
    },
    box: {
      display: "flex",
      "& p": {
        marginRight: "5px",
      },
    },
    paper: {
      width: "100%",
      // marginBottom: theme.spacing(2),
      background: "transparent",
    },
    wrap: {
      padding: "0 10px",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "nowrap",
        padding: 0,
      },
    },
    table: {
      minWidth: 750,
      "& .MuiTooltip-arrow": {
        marginLeft: "-15px",
      },
      "& .MuiTableSortLabel-root:hover": {
        color: "#fff !important",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    margin: {
      margin: theme.spacing(1),
    },
    tooltip: {
      padding: "16px 16px 16px 12px",
      "& span:first-of-type": {
        marginRight: "3px",
      },
    },
    bar: {
      width: "74.5%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },

      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
      "& .MuiInput-input": {
        color: "#fff",
      },
      "& .MuiIconButton-root:hover": {
        background: "transparent",
      },
    },
    selectItem: {
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
      "& .MuiButton-root": {
        border: `1px solid ${theme.palette.primary.main}`,
        padding: "10px",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      "& .MuiButton-label .MuiButton-endIcon": {
        [theme.breakpoints.up("sm")]: {
          marginLeft: 100,
        },
      },
    },
    test: {
      border: "1px solid red",
    },
    hideBlock: {
      display: "none",
    },
    none: {
      display: "none !important",
    },
  })
);

export { useStyles };
