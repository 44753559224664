import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    // maxWidth: 200,
    backgroundColor: "rgba(239, 166, 24,0.2)", //"#F8F8F8",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  text: {
    fontSize: "0.7rem",
  },
  iconLoud: {
    width: 19.44,
    height: 20,
    marginRight: `${theme.spacing(2)}px`,
  },
}));

export default useStyles;
