import { useState, useEffect } from "react";
import axios from "axios";

import useStyles from "./styles";
import { UN_KNOWN_LOGO } from "../../config/Constants";

interface ITokenLogo {
  uri: string;
  className?: string;
}

const TokenLogo: React.FC<ITokenLogo> = ({ uri, className }) => {
  const [logo, setLogo] = useState<string>(UN_KNOWN_LOGO);
  const classes = useStyles();

  const getTokenLogo = async (uri: string): Promise<string> => {
    let _uri = uri;
    try {
      if (uri.startsWith("ipfs")) {
        const _val = uri?.split("//");
        _uri = "https://ipfs.io/ipfs/" + _val[1];
      }
      const res = await axios.get(_uri);
      if (res) {
        return _uri;
      } else {
        return UN_KNOWN_LOGO;
      }
    } catch (error) {
      // logError("Token Logo", error);
      return "not found";
      // return UN_KNOWN_LOGO;
    }
  };

  const fetchLogo = async () => {
    setLogo(await getTokenLogo(uri));
  };

  useEffect(() => {
    fetchLogo();
  }, [uri]);

  return (
    <img
      // style={{ marginTop: 5 }}
      src={logo}
      alt="icon"
      width="20px"
      height="20px"
    />
  );
};

export default TokenLogo;
