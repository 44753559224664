import { combineReducers } from "redux";

import uiReducer from "./uiReducer";

export const rootReducer = combineReducers({
  ui: uiReducer,
});

export default rootReducer;

export type State = ReturnType<typeof rootReducer>;
