import React from "react";
import { Box, Grid, GridProps, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { CTooltip } from "../";
import { trunc } from "../../utils/formating";

interface IContainerSpaceBetween {
  Component01: any;
  Component02: any;
  className01?: any;
  className02?: any;
  className03?: any;
  justify?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  xs01?: any;
  xs02?: any;
}

interface IStringsSpaceBetween {
  string01: any;
  string02: any;
  className01?: any;
  className02?: any;
  className03?: any;
  str02InTrunc?: boolean;
}

export const ContainerSpaceBetween: React.FC<IContainerSpaceBetween> = ({
  Component01,
  Component02,
  className01,
  className02,
  className03,
  justify,
  xs01 = 4,
  xs02 = 8,
}) => {
  return (
    <Grid
      className={className01}
      container
      alignItems="center"
      justify={justify ? justify : "space-between"}
    >
      <Grid item xs={xs01} className={className02}>
        {Component01}
      </Grid>
      <Grid item xs={xs02} className={className03}>
        {Component02}
      </Grid>
    </Grid>
  );
};

export const StringsSpaceBetween: React.FC<IStringsSpaceBetween> = ({
  string01,
  string02,
  className01,
  className02,
  className03,
  str02InTrunc = false,
}) => {
  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      className={className01}
    >
      <Grid item>
        <Typography className={className02} variant="caption">
          {string01}
        </Typography>
      </Grid>
      {str02InTrunc ? (
        <Grid item>
          <CTooltip title={`${string02}`}>
            <Typography className={className03} variant="caption">
              {`$ ${trunc(parseFloat(string02))}`}
            </Typography>
          </CTooltip>
        </Grid>
      ) : (
        <Grid item>
          <Typography className={className03} variant="caption">
            ${string02}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

interface IOverlapIcons {
  Icon01: React.FC<any>;
  Icon02: React.FC<any>;
  class01?: any;
  class02?: any;
}

export const OverlapIcons: React.FC<IOverlapIcons> = ({
  Icon01,
  Icon02,
  class01,
  class02,
}) => {
  const classes = useStyles();
  return (
    <Box position="relative">
      <Box className={classes.iconContainer01}>
        <Icon01 className={class01 ? class01 : classes.icon} />
      </Box>
      <Box className={classes.iconContainer02}>
        <Icon02 className={class02 ? class02 : classes.icon} />
      </Box>
    </Box>
  );
};

interface IRowAlignCenter {
  elements01: React.ReactNode;
  elements02: React.ReactNode;
  justify?: GridProps["justify"];
  containerClassName?: string;
  element01ClassName?: string;
  element02ClassName?: string;
}

export const RowAlignCenter: React.FC<IRowAlignCenter> = ({
  elements01,
  elements02,
  justify,
  containerClassName,
  element01ClassName,
  element02ClassName,
}) => {
  return (
    <Grid
      className={containerClassName}
      container
      alignItems="center"
      justify={justify ? justify : "space-between"}
    >
      <Grid className={`${element01ClassName}`} item>
        {elements01}
      </Grid>
      <Grid className={`${element02ClassName}`} item>
        {elements02}
      </Grid>
    </Grid>
  );
};

interface IRowThreeContent {
  iconWithStr: any;
  greenInfo?: any;
  amount: string;
  className03?: string;
}

export const RowThreeContent: React.FC<IRowThreeContent> = ({
  iconWithStr,
  greenInfo,
  amount,
  className03,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      style={{ padding: "15px 0px" }}
    >
      <Grid item xs={7}>
        <RowAlignCenter elements01={iconWithStr} elements02={greenInfo} />
      </Grid>
      <Grid item>
        <CTooltip title={`${amount}`}>
          <Typography className={className03 ? className03 : classes.amount}>
            {trunc(parseFloat(amount))}
          </Typography>
        </CTooltip>
      </Grid>
    </Grid>
  );
};
