import React from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";

import useStyles from "./styles";

import CloseIcon from "@material-ui/icons/Close";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SettingsIcon from "@material-ui/icons/Settings";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

interface IButton {
  onClick?: (value: any) => any;
  classNameContainer?: string;
  classNameIcon?: string;
}

export const CloseButton: React.FC<IButton | any> = ({
  onClick = () => {},
}) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <CloseIcon />
    </IconButton>
  );
};

export const BackButton: React.FC<IButton | any> = ({ onClick = () => {} }) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <KeyboardBackspaceIcon />
    </IconButton>
  );
};

export const SettingsButton: React.FC<IButton | any> = ({
  onClick = () => {},
  ...rest
}) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} {...rest} className={classes.closeButton}>
      <SettingsIcon />
    </IconButton>
  );
};

export const FileCopyButton: React.FC<IconButtonProps & IButton> = ({
  onClick = () => {},
  classNameContainer,
  classNameIcon,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      size="small"
      onClick={onClick}
      className={[classes.closeButton, classNameContainer ?? ""].join(" ")}
    >
      <FileCopyIcon className={classNameIcon} />
    </IconButton>
  );
};

export const TickButton: React.FC<IconButtonProps & IButton> = ({
  onClick = () => {},
  classNameContainer,
  classNameIcon,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      size="small"
      onClick={onClick}
      className={[classes.closeButton, classNameContainer ?? ""].join(" ")}
    >
      <CheckCircleIcon className={classNameIcon} />
    </IconButton>
  );
};
