import React, { useState, useEffect } from "react";
import {
  Container,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";

import useStyles from "./styles";
import EnhancedTable from "../../components/table";
import { subgraphRequest } from "../../utils/helpers";
import { checkBlackList } from "../../utils/helpers";
import { Data } from "../../components/table";

const Main: React.FC<{}> = () => {
  const [loading01, setLoading01] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<number>(0);
  const [participants, setParticipants] = useState<number>(0);

  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down("xs"));
  const sm_ = useMediaQuery(theme.breakpoints.down("sm"));

  const sizeGrid = xs_ ? 12 : 6;

  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;

  let params = {
    transactionHashes: {
      id: true,
      txCount: true,
    },
    userEs: {
      id: true,
      txCount: true,
    },
  };

  function getTotal(total: number, num: number) {
    return total + num;
  }

  const getData = async () => {
    setLoading01(true);
    let dataRow;
    //@ts-ignore
    params.userEs.__args = {
      first: 1000,
    };
    if (pathname === "/phase2") {
      dataRow = await subgraphRequest(
        "https://api.thegraph.com/subgraphs/name/hamzabhatti125/leaderboardv2round2",
        params
      );
    } else {
      dataRow = await subgraphRequest(
        "https://api.thegraph.com/subgraphs/name/hamzabhatti125/leaderboardv2",
        params
      );
    }

    const checkData = dataRow.userEs.filter((row: Data) =>
      checkBlackList(row.id)
    );
    if (checkData.length) {
      setParticipants(checkData.length);
    }

    const trans = dataRow.transactionHashes.map((row: any) => {
      return parseFloat(row.txCount);
    });
    if (trans.length) {
      const total = trans.reduce(getTotal);
      setTransactions(total);
    }
    setLoading01(false);
  };

  useEffect(() => {
    getData();
  }, [pathname]);

  return (
    <>
      <Container
        disableGutters={!xs_}
        maxWidth="xl"
        style={{ marginTop: sm_ ? "20px" : "0" }}
      >
        <Box className={classes.conatiner}>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h3" className={classes.heading}>
                Unipilot Competition Leaderboard
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="stretch"
            spacing={2}
            direction={xs_ ? "column" : "row"}
          >
            <Grid item md={6} sm={sizeGrid}>
              <BoxMain
                value={participants}
                label="Total Participants"
                loading={loading01}
              />
            </Grid>
            <Grid item md={6} sm={sizeGrid}>
              <BoxMain
                value={transactions}
                label="Total Transactions"
                loading={loading01}
              />
            </Grid>
          </Grid>
          <Box className={classes.marginTop20} />
          <Grid
            container
            className={classes.boxContainer}
            alignItems="stretch"
            direction={xs_ ? "column" : "row"}
            style={{
              padding: !sm_ ? "25px 0 0 0" : "25px 0",
              marginBottom: sm_ ? "85px" : undefined,
            }}
          >
            <Grid
              item
              md={12}
              lg={12}
              sm={12}
              xs={12}
              style={{ marginTop: "20px" }}
            >
              <EnhancedTable pathname={pathname} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Main;

const BoxMain = ({ value = 100, label = "LP Value", loading = false }: any) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.boxContainer}
      container
      alignItems="center"
      direction="column"
    >
      <Grid item className={classes.marginTop}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography style={{ fontSize: "1rem", fontWeight: 700 }}>
              {label}
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></Grid>
        </Grid>
      </Grid>
      <Grid item style={{ width: "100%", textAlign: "center" }}>
        <Typography className={classes.boxText}>
          {loading ? <CircularProgress size={20} /> : value}
        </Typography>
      </Grid>
    </Grid>
  );
};
