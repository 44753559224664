import { Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

interface IGreenInfo {
  content: any;
}

const GreenInfo: React.FC<IGreenInfo> = ({ content }) => {
  const classes = useStyles();
  return <Typography classes={{ root: classes.root }}>{content}%</Typography>;
};

export default GreenInfo;
