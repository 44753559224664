import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  eth: {
    width: 14,
    height: 14,
  },
  pilotIcon: {
    width: 30,
    height: 30,
  },
}));

export default useStyles;
