import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  conatiner: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.common.black
        : theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: "1.5rem",
  },
  tokenPair: {
    margin: "15px 0px",
  },
  marginTop: {
    marginTop: 20,
  },
  paddingRight5: {
    paddingRight: 6,
  },
  paddingLeft5: {
    paddingLeft: 6,
  },
  buttonBottom: {
    // backgroundColor: "red",
    textTransform: "none",
    color: "white",
    padding: "8px 0px",
  },
  textMarginTop: {
    marginTop: 5,
  },
  bar: {
    "&:hover": {
      color: "red",
    },
  },
  topLeftBtn: {
    textTransform: "none",
    padding: "8px 0px",
  },
  marginTopMost: {
    marginTop: 100,
  },
  bottomSelect: {
    borderRadius: 0,
  },
  bottomInput: {
    borderRadius: 0,
  },
  inputMaxContainer: {
    backgroundColor: theme.palette.secondary.main, //"#F8F8F8",
    fontWeight: 500,
    padding: "4px 15px",
    borderRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderLeft: `2px solid rgba(255,255,255,0.1)`,
  },
  inputStartingPrice: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
    padding: "4px 15px",
    fontWeight: 500,
    color: theme.palette.common.white,
    marginTop: 5,
  },
}));

export default useStyles;
