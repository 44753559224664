import { Grid, Typography } from "@material-ui/core";

import React from "react";
import { useHistory } from "react-router";

import { BackButton, SettingsButton } from "../";

interface IHeader {
  disableGoBack?: boolean;
  elements?: any;
  iconRight?: any;
  goBack?: () => void;
  onClickSettings?: (v: any) => void;
  settingsProps?: any;
}

const Header: React.FC<IHeader> = ({
  elements = <Typography variant="h5">Header</Typography>,
  disableGoBack = false,
  iconRight,
  goBack,
  onClickSettings = () => {},
  settingsProps,
}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Grid container alignItems="flex-start" justify="space-between">
        <Grid item style={{ visibility: disableGoBack ? "hidden" : "visible" }}>
          <BackButton
            onClick={
              goBack
                ? goBack
                : () => {
                    history.goBack();
                  }
            }
          />
        </Grid>
        <Grid item>{elements}</Grid>
        <Grid item>
          {iconRight ? (
            iconRight
          ) : (
            <SettingsButton onClick={handleClick} {...settingsProps} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
