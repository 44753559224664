export interface Data {
  name: string;
  percent: string;
  address: string;
  isWhiteListed: boolean;
  logo1: string;
  logo2: string;
}

const maindata: Data[] = [
  {
    name: "UNI/ETH",
    percent: "0.3",
    address: "0x0b2aaa72f696ed6b89dcf888b4a15c7320b6934a",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "USDT/ETH",
    percent: "0.3",
    address: "0x9451c48e014a679be04f19976361605360fd2a2b",
    isWhiteListed: true,
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "DAI/ETH",
    percent: "0.3",
    address: "0x95a2bf861422bd1e9d9206264613a2f8bed9f58d",
    isWhiteListed: true,
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "ETH/USDC ",
    percent: "0.3",
    address: "0xf2d25695f277f860aa149e1e4e3d8246d6dc215b",
    isWhiteListed: true,
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
    logo2:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
  },
  {
    name: "WBTC/ETH",
    percent: "0.3",
    address: "0xebc44278acfca37c36dd2d5a4b2202b0c39a1f39",
    isWhiteListed: true,
    logo1:
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "USDC/USDT",
    percent: "1",
    address: "0x7fc2eddb03e97c7495132661f9a4a92f931189b1",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    logo2:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  },
  {
    name: " UNI/USDT",
    percent: "0.3",
    address: "0x68249df95ee9349450fcfbaa72264d98f27bc567",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
    logo2:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  },
  {
    name: "USDT/WBTC",
    percent: "0.3",
    address: "0xa9ac8bca9f2823154a8bd2a3ec29b247206d6b91",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
  },
  {
    name: "DAI/USDT",
    percent: "0.3",
    address: "0x8d8c3c154c2cb7a4137b0c35a45d126e580adb84",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  },
  {
    name: "PILOT/ETH",
    percent: "0.3",
    address: "0x82d30c44c6372c4e5b30fb15134e08db69ab11fe",
    isWhiteListed: true,
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmZkwHmYpVL3Bi17SUsmHU4pypkTXan46JH76ToRgoZJQz",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
];

const maindataPhase2: Data[] = [
  {
    name: "UNI/ETH",
    percent: "0.3",
    address: "0x996cbf586d366d78b4b8998b74ca2c9ebceff5f9",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "DAI/ETH",
    percent: "0.3",
    address: "0x4fdc4fecdd6a77fb28b727583817e2c05d0de10a",
    isWhiteListed: true,
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "ETH/USDC ",
    percent: "0.3",
    address: "0x56e0eeca868b2aa00162cc80a18929212996332b",
    isWhiteListed: true,
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
    logo2:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
  },
  {
    name: "WBTC/ETH",
    percent: "0.3",
    address: "0x7d019a287e69de852739c8fe8070d75c9dc06ec4",
    isWhiteListed: true,
    logo1:
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
    logo2:
      "https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip",
  },
  {
    name: "USDC/USDT",
    percent: "1",
    address: "0x51340972bbe560ec4bc6359940137c5e9a87b057",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    logo2:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  },
  {
    name: " UNI/USDT",
    percent: "0.3",
    address: "0x68249df95ee9349450fcfbaa72264d98f27bc567",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
    logo2:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  },
  {
    name: "USDT/WBTC",
    percent: "0.3",
    address: "0xa9ac8bca9f2823154a8bd2a3ec29b247206d6b91",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    logo2:
      "https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
  },
  {
    name: "DAI/USDT",
    percent: "0.3",
    address: "0x8d8c3c154c2cb7a4137b0c35a45d126e580adb84",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://tokens.1inch.exchange/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
  },
  {
    name: "PILOT/DAI",
    percent: "0.3",
    address: "0xaeca6d9d06f3f2aa285455ea4defd21c9d6cfaed",
    isWhiteListed: true,
    logo1:
      "https://gateway.pinata.cloud/ipfs/QmZkwHmYpVL3Bi17SUsmHU4pypkTXan46JH76ToRgoZJQz",
    logo2:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
  },
  {
    name: "DAI/WETH",
    percent: "0.3",
    address: "0x54d8740e0f43d15facbabf26da38ed7cc8653348",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    logo2:
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  },
  {
    name: "USDC/WETH",
    percent: "0.3",
    address: "0x8b428d0e863d978a99c1b5a6dac956a4aa1fc43d",
    isWhiteListed: false,
    logo1:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    logo2:
      "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  },
  {
    name: "ETH/USDC",
    percent: "0.05",
    address: "0x4a45ffb564d9ebdee88e6c99768f46c7a86c6f61",
    isWhiteListed: true,
    logo1:
      "https://tokens.1inch.exchange/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png",
    logo2:
      "https://tokens.1inch.exchange/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
  },
];

export { maindata, maindataPhase2 };
