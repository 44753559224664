import Routes from './Routes';
import { TDialog, Snack } from '../components';
import { useModal } from '../hooks';

function Layout() {
  const { modalProps } = useModal();
  return (
    <>
      <Snack />
      <TDialog {...modalProps} />
      <Routes />
    </>
  );
}

export default Layout;
