import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxGreen: {
    width: 16,
    height: 16,
    backgroundColor: "green",
    borderRadius: 15,
  },
  boxOrange: {
    width: 20,
    height: 20,
    color: "orange",
  },
  iconContainer: {
    paddingTop: 4,
  },
  rangeInfoGreen: {
    fontSize: "0.8rem",
    color: "#67b631",
    backgroundColor: "rgba(103, 182, 49,0.1)",
    padding: "5px 15px",
    borderRadius: theme.shape.borderRadius,
  },
  rangeInfoOrange: {
    fontSize: "0.8rem",
    color: theme.palette.primary.main,
    backgroundColor: "rgba(239, 166, 24,0.1)",
    padding: "5px 15px",
    borderRadius: theme.shape.borderRadius,
  },
}));

export default useStyles;
