import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { FortmaticConnector } from '@web3-react/fortmatic-connector';
import { PortisConnector } from '@web3-react/portis-connector';
import { isMobile } from 'react-device-detect';

import { CHAIN_ID } from '../config/Constants';

const REACT_APP_NETWORK_URL1 = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`;
const REACT_APP_NETWORK_URL4 = `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`;

const FORTMATIC_KEY =
  process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION'
    ? process.env.REACT_APP_FORTMATIC_PRODUCTION_KEY
    : process.env.REACT_APP_FORTMATIC_DEVELOPMENT_KEY;

const PORTIS_KEY =
  process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION'
    ? process.env.REACT_APP_PRODUCTION_PORTIS_ID
    : process.env.REACT_APP_DEVELOPMENT_PORTIS_ID;

const POLLING_INTERVAL = 12000;

const RPC_URLS = {
  1: REACT_APP_NETWORK_URL1,
  4: REACT_APP_NETWORK_URL4,
};

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 4: RPC_URLS[CHAIN_ID] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[CHAIN_ID],
  appName: 'Flashstake',
});

export const fortmatic = new FortmaticConnector({
  apiKey: FORTMATIC_KEY ?? '',
  chainId: CHAIN_ID,
});

export const portis = new PortisConnector({
  dAppId: PORTIS_KEY ?? '',
  networks: [CHAIN_ID],
});

export const injectedConnectorList = [
  {
    name: 'Metamask',
    connector: injected,
    connectorType: InjectedConnector,
  },
  {
    name: 'Injected',
    connector: injected,
    connectorType: InjectedConnector,
  },
];

export const desktopWalletList = [
  {
    name: 'Metamask',
    connector: injected,
    connectorType: InjectedConnector,
  },
  {
    name: 'WalletConnect',
    connector: walletconnect,
    connectorType: WalletConnectConnector,
  },
  {
    name: 'Coinbase',
    connector: walletlink,
    connectorType: WalletLinkConnector,
  },
  {
    name: 'Fortmatic',
    connector: fortmatic,
    connectorType: FortmaticConnector,
  },
  {
    name: 'Portis',
    connector: portis,
    connectorType: PortisConnector,
  },
];

export const mobileWalletList = [
  {
    name: 'WalletConnect',
    connector: walletconnect,
    connectorType: WalletConnectConnector,
  },

  {
    name: 'Fortmatic',
    connector: fortmatic,
    connectorType: FortmaticConnector,
  },
  {
    name: 'Portis',
    connector: portis,
    connectorType: PortisConnector,
  },
];

export const walletList = isMobile
  ? window?.ethereum
    ? [window?.ethereum?.isMetaMask ? injectedConnectorList[0] : injectedConnectorList[1]]
    : mobileWalletList
  : desktopWalletList;
