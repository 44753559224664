import axios from "axios";

import { PILOT_ADDRESS } from "../../config/Constants";

export const updatePilotPrice = async () => {
  let pilotPrice: number;
  try {
    const uri = `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${PILOT_ADDRESS}&vs_currencies=USD`;
    const res = await axios.get(uri);
    if (res.data) {
      pilotPrice = res.data[PILOT_ADDRESS].usd;
      return pilotPrice;
    }
    return 0;
  } catch (err) {
    return 0;
  }
};
