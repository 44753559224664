import { useSelector } from "react-redux";
import { useDispatchWrap } from "./utilHook";
import {
  State,
  hideModal,
  showSnackbar,
  hideSnackbar,
  showSnackbarInDeep,
} from "../redux";

export const useModal = () => {
  const { modal } = useSelector((state: State) => state.ui);
  const hideModalF = useDispatchWrap(hideModal);
  return { modalProps: modal, hideModalF };
};

export const useLoading = () => {
  const { dappLoading, isApproved } = useSelector((state: State) => state.ui);
  return { dappLoading, isApproved };
};

export const useSnackbar = () => {
  const snackbar = useSelector((state: any) => state.ui.snackbar);
  const showSnackbarF = useDispatchWrap(showSnackbar);
  const hideSnackbarF = useDispatchWrap(hideSnackbar);
  const showSnackbarInDeepF = useDispatchWrap(showSnackbarInDeep);

  return { ...snackbar, showSnackbarF, hideSnackbarF, showSnackbarInDeepF };
};
